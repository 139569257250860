<template>
  <div>
     
        <p><br/></p>
        <h2 
            class="text-uppercase bottom20"
            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
            {{ $t('app.video_presentation') }}
        </h2>
        <div class="row bottom40">
            <div class="col-md-12 padding-b-20">
                <div class="pro-video">
                    <iframe class="wpf-demo-gallery"
                            :src="embed_video"
                            style="width: 100%; height:500px">
                    </iframe>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
export default {
    name: 'VideoCard',
    props: ['embed_video'],
    data(){
        return {
            //
        }
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
